import { handleError } from '@propify-tenant-client/services';

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority(str?: string): string | string[] {
  const authorityString = typeof str === 'undefined' ? localStorage.getItem('nhr-auth') : str;
  // authorityString could be admin, "admin", ["admin"]
  let authority;
  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (error) {
    handleError(error, { displayToast: false });
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority;
}

export function setAuthority(authority: string | string[]): void {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem('nhr-auth', JSON.stringify(proAuthority));
}

export function setAuthorityToken(token: string): void {
  localStorage.setItem('id_token', token);
}
