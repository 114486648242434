import { EnvironmentService, SentryService } from '@propify-tenant-client/services';
import { Big } from 'big.js';
import ReactGA from 'react-ga';

EnvironmentService.init();
SentryService.init();

Big.RM = 2;
Big.DP = 10;

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-180474954-2');
}
