import type { Effect, Model } from '@/models/connect';
import { combineReducers, createSlice } from '@reduxjs/toolkit';
import * as sagaEffects from 'redux-saga/effects';

export const initialize = (models: Model[]) => {
  const effects = models
    .map((model) =>
      Object.entries(model.effects ?? {}).map(([key, value]) => ({
        namespace: model.namespace,
        name: `${model.namespace}/${key}`,
        effect: value,
      })),
    )
    .flat();

  const loadingNamespace = 'loading';

  const sagas = effects.map((effect) => {
    return function* () {
      yield sagaEffects.takeEvery(effect.name, function* runFindById(action) {
        try {
          yield sagaEffects.put({ type: loadingNamespace + '/' + effect.name + '/start' });

          yield sagaEffects.call<Effect>(effect.effect, action, {
            ...sagaEffects,
            // @ts-ignore
            put: (...args: any[]) => {
              const getTypeWithNamespace = (type: string) => {
                let typeWithNamespace = type;
                if (!type.includes('/')) {
                  typeWithNamespace = effect.namespace + '/' + type;
                }
                return typeWithNamespace;
              };

              if (args[0].type) {
                const putAction = args[0];
                return sagaEffects.put({
                  ...putAction,
                  type: getTypeWithNamespace(putAction.type.toString()),
                });
              } else {
                const putAction = args[1];
                return sagaEffects.put(args[0], {
                  ...putAction,
                  type: getTypeWithNamespace(putAction.type.toString()),
                });
              }
            },
          });
        } finally {
          yield sagaEffects.put({ type: loadingNamespace + '/' + effect.name + '/end' });
        }
      });
    };
  });

  const loadingSlice = createSlice({
    name: loadingNamespace,
    initialState: {
      effects: effects.reduce(
        (obj, effect) => ({
          ...obj,
          [effect.name]: false,
        }),
        {},
      ),
    },
    reducers: effects.reduce(
      (obj, effect) => ({
        ...obj,
        [effect.name + '/start']: (state: any) => {
          state.effects[effect.name] = true;
        },
        [effect.name + '/end']: (state: any) => {
          state.effects[effect.name] = false;
        },
      }),
      {},
    ),
  });

  const slices = models.map((model) =>
    createSlice({
      name: model.namespace,
      initialState: model.state,
      reducers: model.reducers ?? {},
    }),
  );

  const reducers = [...slices, loadingSlice].reduce(
    (others, slice) => ({
      ...others,
      [slice.name]: slice.reducer,
    }),
    {},
  );

  const rootReducer = combineReducers(reducers);

  return {
    rootReducer,
    sagas,
  };
};
