import ReactGA from 'react-ga';

export const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

export const GAEvent = (category: string, action: string, label?: string) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};
